<template>
  <div class="offer-details p-t-30">
    <div class="container">
      <template v-if="isLoading">
        <SingleOfferLoader />
      </template>

      <template v-else>
        <div class="row">
          <div class="col-xl-6 col-lg-12 col-md-12">
            <offer-slider
              v-if="imagesUrls.length"
              :images="imagesUrls"
            />
            <div
              v-else
              class="img-container"
            >
              <img
                :src="offerDetails.offer_image"
                alt="offer_image"
              >
            </div>
            <div
              class="about-seller d-mobile-none white-color-bg p-y-24 p-x-12 radius-20 m-t-12"
            >
              <!-- to be discussed -->
              <!-- <h3 class="bold-font fs-24">
              {{ $t('offers.marchent') }}
            </h3> -->

              <div
                class="seller-info m-y-20 d-flex justify-content-start align-items-center"
              >
                <img
                  v-if="offerDetails.partners"
                  :src="offerDetails.partners.part_logo"
                  width="60"
                  height="60"
                  class="radius-full"
                  alt=""
                >
                <div class="m-l-14">
                  <h4
                    v-if="offerDetails.partners"
                    class="medium-font fs-20"
                  >
                    {{ offerDetails.partners.part_name }}
                  </h4>
                  <span
                    v-if="offerDetails.partners"
                    class="medium-font main-color-text font-weight-600 fs-14"
                  >
                    {{ offerDetails.type_price.length }}
                    <span v-if="offerDetails.type_price.length == 1">
                      {{ $t('offers.offer') }}</span>
                    <span v-else> {{ $t('offers.offer') }}</span>
                  </span>
                </div>
              </div>

              <OfferDetailsTabs
                :menuImages="offerDetails.partners"
                :branches="offerDetails.branches"
                :conditions="offerDetails.offer_conditions"
              />
            </div>
          </div>

          <div class="col-xl-6 col-lg-12 col-md-12">
            <div class="offer-info m-t-50 m-b-20">
              <div class="d-flex justtify-content-start align-items-center">
                <h2 class="font-bold fs-32 m-r-8">
                  {{ offerDetails.offer_name }}
                </h2>

                <div
                  class="rate d-flex justify-content-center align-items-center"
                >
                  <StarIcon />
                  <span class="secondary-font gold-color-text">
                    {{ Math.round(offerDetails.rate * 10) / 10 }}
                  </span>
                </div>
              </div>

              <p class="fs-18 dark-gray-color-text m-y-22">
                {{ offerDetails.offer_brief }}
              </p>

              <div
                class="price d-flex justify-content-start align-items-center"
              >
                <span class="main-color-bg white-color-text medium-font">
                  {{ offerDetails.actual_value }} {{ $t('account.egp') }}
                </span>
                <span class="dark-gray-color-text medium-font">
                  {{ offerDetails.offer_value }} {{ $t('account.egp') }}
                </span>
              </div>
            </div>

            <div class="offer-content white-color-bg p-y-24 p-x-12 radius-20">
              <div clas="d-flex justify-content-between align-items-center">
                <h3 class="bold-font fs-24 m-b-20">
                  {{ $t('offers.offerContent') }}
                </h3>
              </div>

              <!--Offer Details -->
              <div class="coupons">
                <div
                  v-for="coupon in offerDetails.type_price"
                  :key="coupon.type_price_id"
                  class="coupon p-y-20 p-x-10 radius-12 m-b-18"
                >
                  <OfferCoupons
                    :item="coupon"
                    :offerDetails="offerDetails"
                  />
                </div>
              </div>

              <!-- <div class="m-t-16">
              <h4 class="bold-font fs-20 m-b-20">
                {{ $t('home.conditions') }}
              </h4>

              <p
                class="fs-16 m-b-6 light-gray-color-text mr-3 offer-conditions"
                v-html="offerDetails.offer_conditions"
              />
            </div> -->
            </div>

            <div
              class="about-seller d-md-none white-color-bg p-y-24 p-x-12 radius-20 m-t-12"
            >
              <h3 class="bold-font fs-24">
                {{ $t('offers.marchent') }}
              </h3>

              <div
                class="seller-info m-y-20 d-flex justify-content-start align-items-center"
              >
                <img
                  v-if="offerDetails.partners"
                  :src="offerDetails.partners.part_logo"
                  width="60"
                  height="60"
                  class="radius-full"
                  alt=""
                >
                <div class="m-l-14">
                  <h4
                    v-if="offerDetails.partners"
                    class="medium-font fs-20"
                  >
                    {{ offerDetails.partners.part_name }}
                  </h4>
                  <span
                    v-if="offerDetails.partners"
                    class="medium-font main-color-text font-weight-600 fs-14"
                  >
                    {{ offerDetails.type_price.length }}
                    <span v-if="offerDetails.type_price.length == 1">
                      {{ $t('offers.offer') }}</span>
                    <span v-else> {{ $t('offers.offer') }}</span>
                  </span>
                </div>
              </div>

              <OfferDetailsTabs
                :menuImages="offerDetails.partners"
                :branches="offerDetails.branches"
              />
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="container-fluid more-offers p-t-70 p-b-50 mt-5">
      <MoreOffer />
    </div>
  </div>
</template>

<script>
import StarIcon from '@/components/icons/StarIcon'
import OfferCoupons from './offer-coupons/index.vue'
import OfferDetailsTabs from './tabs/Index.vue'
import OfferSlider from './offer-slider/Index.vue'
import MoreOffer from './more-offers/Index.vue'
import SingleOfferLoader from './SingleOfferLoader.vue'

export default {
  name: 'OfferDetails',
  components: {
    MoreOffer,
    StarIcon,
    OfferDetailsTabs,
    OfferSlider,
    OfferCoupons,
    SingleOfferLoader
  },
  data () {
    return {
      counter: 0,
      payload: {}
    }
  },

  computed: {
    isLoading () {
      return this.$store.state.offers.loading
    },

    isLoggedIn () {
      return this.$store.getters['Globals/checkUserLogin']
    },

    offerDetails () {
      return this.$store.state.offers.offerDetails
    },

    imagesUrls () {
      if (this.offerDetails.images && this.offerDetails.images.length) {
        return this.offerDetails.images.map((img) => img.slide_image)
      } else {
        return []
      }
    }
  },

  // call single offer when make any router change
  watch: {
    $route () {
      this.getSingleOffer()
    }
  },
  mounted () {
    this.getSingleOffer()
  },

  methods: {
    addToCart (counter, coupon) {
      this.payload = {
        'items[0][offer_id]': this.offerDetails.offer_id,
        'items[0][type_id]': coupon.type_price_id,
        'items[0][qty]': counter,
        'device_token': this.getUserIp
      }
      if (!this.isLoggedIn) {
        this.payload = {
          ...this.payload
        }
      } else {
        this.payload = {
          ...this.payload,
          token: this.getUserToken
        }
      }

      this.$store.dispatch('cart/addToCart', this.payload).then(() => {})
    },

    getSingleOffer () {
      const payload = {
        app_version: '',
        store: '',
        offer_id: this.$route.params.id
      }
      this.offerLoading = true

      this.$store
        .dispatch('offers/getSingleOffer', payload)
        .then(() => {})
        .catch((err) => {})
    }
  }
}
</script>

<style lang="scss" scoped>
@import './offer-details';
</style>
