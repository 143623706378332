<template>
  <div class="offer-slider">
    <!-- <VueSkeletonLoader
      v-show="offerLoading"
      class="single-image-loader"
      type="rect"
      animation="wave"
    /> -->
  </div>
</template>

<script>
// import VueSkeletonLoader from 'skeleton-loader-vue'

export default {
  name: 'SingleSliderLoader',
  // components: { VueSkeletonLoader },
  data () {
    return {
      offerLoading: false
    }
  },
  mounted () {
    this.offerLoading = true
  }
}
</script>

<style lang="scss">
@import './offer-slider';
</style>
