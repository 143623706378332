<template>
  <section class="offer-coupon">
    <div
      class="d-flex justify-content-between align-items-center cursor-pointer w-100"
      @click="showMenu"
    >
      <p class="fs-20 font-medium m-b-18">
        {{ item.type_price_name }}
      </p>
      <ArrowDown :class="{ rotate: displayDropdown }" />
    </div>

    <div
      class="coupon-content d-flex justify-content-between align-items-center"
    >
      <div class="d-flex justify-content-between align-items-center w-100">
        <div class="price d-flex justify-content-start align-items-center">
          <p class="fs-18 bold-font main-color-text m-x-10">
            {{ item.type_price_price }}
            {{ $t('account.egp') }}
          </p>
          <span class="dark-gray-color-text medium-font">
            {{ offerDetails.offer_value }} {{ $t('account.egp') }}
          </span>
        </div>
        <!-- Add To Cart --->
        <AddToCart @onAddToCart="addToCart($event, item)" />
      </div>
    </div>

    <transition name="fadeUp">
      <div
        v-if="displayDropdown & (item.type_price_description != null)"
        class="dropdown-body white-color-bg p-y-18 p-x-16 radius-8"
      >
        <p
          class="fs-16 light-gray-color-text offer-conditions mr-3"
          v-html="item.type_price_description"
        />
      </div>
    </transition>
  </section>
</template>

<script>
import AddToCart from '../addToCart/index.vue'
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
  name: 'OfferCoupons',
  components: { AddToCart },
  props: {
    item: {
      type: Object
    },
    offerDetails: {
      type: Object
    }
  },

  data () {
    return {
      displayDropdown: false,
      payload: {}
    }
  },

  computed: {
    isLoggedIn () {
      return this.$store.getters['Globals/checkUserLogin']
    }
  },

  methods: {
    showMenu () {
      this.displayDropdown = !this.displayDropdown
    },
    hideMenu () {
      this.displayDropdown = false
    },

    addToCart (counter, coupon) {
      this.payload = {
        'items[0][offer_id]': this.offerDetails.offer_id,
        'items[0][type_id]': coupon.type_price_id,
        'items[0][qty]': counter,
        'device_token': this.getUserIp
      }
      if (!this.isLoggedIn) {
        this.payload = {
          'items[0][offer_id]': this.offerDetails.offer_id,
          'items[0][type_id]': coupon.type_price_id,
          'items[0][qty]': counter,
          'device_token': this.getUserIp
        }
      } else {
        this.payload = {
          ...this.payload,
          token: this.getUserToken
        }
      }

      this.$store.dispatch('cart/addToCart', this.payload).then(() => {
        toast.success(this.$t('offers.addToCart'))
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../offer-details.scss';
</style>
