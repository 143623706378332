<template>
  <div class="tabs">
    <div
      :class="
        menuImages.menu_images && menuImages.menu_images.length
          ? 'miniHeader'
          : 'tabHeader '
      "
    >
      <div
        class="header-item p-y-10 cursor-pointer"
        :class="[
          activeTap === 'availableBranches'
            ? 'main-color-text'
            : 'light-gray-color-text',
        ]"
        @click="activeTap = 'availableBranches'"
      >
        <p class="medium-font fs-18">
          {{ $t('offers.openBranch') }}
        </p>
      </div>

      <!-- v-if="menuImages.menu_images.length" -->
      <div
        v-if="menuImages.menu_images && menuImages.menu_images.length"
        class="header-item p-y-10 cursor-pointer"
        :class="[
          activeTap === 'menuImages'
            ? 'main-color-text'
            : 'light-gray-color-text',
        ]"
        @click="activeTap = 'menuImages'"
      >
        <p class="medium-font fs-18">
          {{ $t('offers.menu') }}
        </p>
      </div>

      <div
        class="header-item p-y-10 cursor-pointer"
        :class="[
          activeTap === 'TermsOfUse'
            ? 'main-color-text'
            : 'light-gray-color-text',
        ]"
        @click="activeTap = 'TermsOfUse'"
      >
        <p class="medium-font fs-18">
          {{ $t('home.conditions') }}
        </p>
      </div>
    </div>

    <div class="tab-content">
      <div v-if="activeTap === 'availableBranches'">
        <AvailableBranchesTap :branches="branches" />
      </div>

      <div v-if="activeTap === 'menuImages'">
        <MenuImagesTap :menuImages="menuImages" />
      </div>

      <div v-if="activeTap === 'TermsOfUse'">
        <TermsOfUse :conditions="conditions" />
      </div>
    </div>
  </div>
</template>

<script>
import AvailableBranchesTap from './AvailableBranches.vue'
import MenuImagesTap from './MenuImages.vue'
import TermsOfUse from './TermsOfUse.vue'
export default {
  name: 'OfferDetailsTabs',
  components: { AvailableBranchesTap, MenuImagesTap, TermsOfUse },
  props: {
    branches: {
      type: Array,
      required: false,
      default: () => []
    },
    menuImages: {
      type: Object,
      required: false,
      default: () => ({})
    },
    conditions: {
      type: String,
      required: false,
      default: () => ''
    }
  },
  data () {
    return {
      activeTap: 'availableBranches' // OR menuImages
    }
  }
}
</script>

<style lang="scss" scoped>
@import './tabs';
</style>
