<template>
  <div class="row">
    <div class="col-xl-6 col-lg-12 col-md-12">
      <!-- <VueSkeletonLoader
        v-show="offerLoading"
        class="single-image"
        type="rect"
        animation="wave"
      /> -->
    </div>

    <div class="col-xl-6 col-lg-12 col-md-12">
      <div class="offer-info m-t-50 m-b-20">
        <div class="d-flex justtify-content-start align-items-center">
          <!-- <VueSkeletonLoader
            v-show="offerLoading"
            class="font-bold fs-32 m-r-8"
            type="rect"
            animation="wave"
          /> -->

          <div class="rate d-flex justify-content-center align-items-center">
            <!-- <VueSkeletonLoader
              v-show="offerLoading"
              class="secondary-font gold-color-text"
              type="rect"
              animation="wave"
            /> -->
          </div>
        </div>

        <!-- <VueSkeletonLoader
          v-show="offerLoading"
          class="fs-18 dark-gray-color-text m-y-22"
          type="rect"
          animation="wave"
        /> -->
      </div>

      <div class="offer-content white-color-bg p-y-24 p-x-12 radius-20">
        <div clas="d-flex justify-content-between align-items-center">
          <!-- <VueSkeletonLoader
            v-show="offerLoading"
            class="bold-font fs-24 m-b-20"
            type="rect"
            animation="wave"
          /> -->
        </div>

        <!--Offer Details -->
        <div class="coupons">
          <!-- <VueSkeletonLoader
            v-show="offerLoading"
            class="coupon p-y-20 p-x-10 radius-12 m-b-18"
            type="rect"
            animation="wave"
          /> -->
        </div>
      </div>

      <div
        class="about-seller d-md-none white-color-bg p-y-24 p-x-12 radius-20 m-t-12"
      >
        <!-- <VueSkeletonLoader
          v-show="offerLoading"
          class="bold-font fs-24"
          type="rect"
          animation="wave"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
// import VueSkeletonLoader from 'skeleton-loader-vue'

export default {
  name: 'SliderLoader',
  // components: { VueSkeletonLoader },
  data () {
    return {
      offerLoading: false
    }
  },
  mounted () {
    this.offerLoading = true
  }
}
</script>

<style lang="scss" scoped>
@import './offer-details';
</style>
