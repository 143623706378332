<template>
  <div class="available-branches-tap m-t-20">
    <div class="row">
      <div
        v-for="(branch, index) in branches"
        :key="index"
        class="col-12 col-md-6"
      >
        <div class="branch m-b-12 radius-8 p-12">
          <div>
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="fs-16 bold-font">
                {{ branch.location.location_name }}
              </h5>

              <div @click="getCoordinats(branch)">
                <a
                  :href="mapUrl"
                  class="text-decoration-none main-color-bg white-color-text p-y-6 p-x-10 fs-12 radius-10"
                  target="_blank"
                >{{ $t('offers.map') }}</a>
              </div>
            </div>
            <p class="fs-14 m-y-12 dark-gray-color-text">
              {{ branch.address }}
            </p>

            <div
              class="details d-flex justify-content-start align-items-center"
            >
              <div
                v-if="branch.work_time !== null"
                class="d-flex justify-content-start align-items-center"
              >
                <TimeIcon />
                <span class="fs-12 dark-gray-color-text m-l-8">
                  {{ branch.work_time }}
                </span>
              </div>

              <div
                v-if="branch.phone !== null"
                class="d-flex justify-content-start align-items-center"
              >
                <PhoneIcon />
                <span class="fs-12 dark-gray-color-text m-l-8">
                  {{ branch.phone }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TimeIcon from '@/components/icons/TimeIcon.vue'
import PhoneIcon from '@/components/icons/PhoneIcon.vue'
export default {
  name: 'AvailableBranchesTap',
  components: { TimeIcon, PhoneIcon },
  props: {
    partners: {
      type: Object,
      required: false,
      default: () => ({})
    },
    branches: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  data () {
    return {
      coordinats: {
        lat: '',
        lng: ''
      }
    }
  },

  computed: {
    mapUrl () {
      const coordinats = `${this.coordinats.lat},${this.coordinats.lng}`
      const url = `https://www.google.com/maps/search/?api=1&query=${coordinats}&z=20`
      return `${url}`
    }
  },

  mounted () {},

  methods: {
    getCoordinats (branch) {
      this.coordinats.lat = branch.latitude
      this.coordinats.lng = branch.longitude
    }
  }
}
</script>

<style lang="scss" scoped>
.available-branches-tap {
  .branch {
    height: 135px;
    border: 1px solid rgba($light-gray-color, 0.5);

    .details {
      gap: 20px;

      svg {
        fill: $dark-gray-color;
        width: 15px;
        height: 15px;
      }
    }
  }
}
</style>
