<template>
  <div class="offer-slider">
    <Carousel
      :wrap-around="true"
      :settings="settings"
    >
      <Slide
        v-for="(image, i) in images"
        :key="`slider-image-${i}`"
        class="img-container"
      >
        <img
          :src="image"
          alt=""
        >
      </Slide>
    </Carousel>
  </div>
</template>

<script>
import SingleSliderLoader from './loader.vue'
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

export default {
  name: 'OfferSlider',
  components: { SingleSliderLoader, Carousel, Slide, Pagination, Navigation },
  props: {
    images: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data () {
    return {
      isLoading: true,
      settings: {
        arrows: false,
        autoplay: 5000
        // "dotsClass": "slick-dots custom-dot-class",
      }
    }
  }
}
</script>

<style lang="scss">
@import './offer-slider';
</style>
