<template>
  <div class="m-t-16">
    <p
      class="fs-16 m-b-6 light-gray-color-text mr-3 offer-conditions"
      v-html="conditions"
    />
  </div>
</template>

<script>
export default {
  name: 'TermsOfUse',
  props: {
    conditions: {
      type: String,
      required: false,
      default: () => ({})
    }
  }
}
</script>
