<template>
  <div class="more-offers">
    <h3 class="watch-offers">
      {{ $t('offers.watchBestOffers') }}
    </h3>

    <Carousel
      v-if="cards.length"
      :wrap-around="true"
      :settings="settings"
      :breakpoints="breakpoints"
    >
      <Slide
        v-for="(card, i) in cards"
        :key="i"
        class="col-12 offer-card-slider"
      >
        <BaseOfferCard :singleCardInfo="card" />
      </Slide>

      <template #addons>
        <Navigation />
      </template>
    </Carousel>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

import OffersCarousel from '../../../services/offers'
import singleOfferApi from '../../../services/singleOffer'

export default {
  name: 'MoreOfferSlider',
  components: { Carousel, Slide, Pagination, Navigation },

  data () {
    return {
      singleProduct: {},
      cards: [],

      settings: {
        infinite: true,
        itemsToShow: 3,
        autoplay: 5000
      },

      breakpoints: {
        1200: {
          itemsToShow: 3,
          autoplay: 5000
        },

        990: {
          itemsToShow: 2,
          autoplay: 5000
        },

        767: {
          itemsToShow: 1.5,
          autoplay: 5000
        },

        500: {
          itemsToShow: 1,
          autoplay: 5000
        },

        480: {
          itemsToShow: 1,
          autoplay: 5000
        }
      }
    }
  },

  created () {
    this.getSectionOffers()

    // fore user when refresh page ... to go to the top of page
    window.onbeforeunload = function () {
      window.scrollTo(0, 0)
    }
  },

  methods: {
    setOfferId (id, name) {
      const offerName = name.replace(/[ "" 25%]/g, '-')
      const path = {
        name: 'offer-details',
        params: { id, offerName }
      }
      if (this.$route.path !== path) this.$router.push(path)
    },

    getSectionOffers () {
      const payload = {
        app_version: '',
        store: '',
        limit: 9
      }
      OffersCarousel.getAllOffers(payload).then((res) => {
        this.cards = res.data.offers
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import './more-offers';
</style>
