<template>
  <div
    :class="isActive ? '' : 'isActive'"
    class="action p-x-12 radius-38 d-flex justify-content-start align-items-center main-color-bg white-color-text"
  >
    <div class="p-y-12 p-x-10 d-flex justify-content-start align-items-center">
      <div
        class="icon cursor-pointer"
        @click="incrementQuantity()"
      >
        <PlusIcon />
      </div>
      <span class="m-x-6 fs-20 font-weight-500">
        {{ counter }}
      </span>
      <div
        class="icon cursor-pointer"
        @click="decrementQuantity()"
      >
        <MinusIcon />
      </div>
    </div>

    <button
      v-if="isActive"
      class="p-y-12 p-x-10 main-color-bg white-color-text medium-font cursor-pointer"
      @click="addToCart()"
    >
      {{ $t('account.addToCart') }}
    </button>

    <button
      v-if="!isActive"
      :class="isActive ? '' : 'isActive'"
      class="p-y-12 p-x-10 main-color-bg white-color-text medium-font"
    >
      {{ $t('account.addToCart') }}
    </button>
  </div>
</template>
<script>
import PlusIcon from '@/components/icons/PlusIcon'
import MinusIcon from '@/components/icons/MinusIcon'
export default {
  name: 'AddToCart',
  components: {
    PlusIcon,
    MinusIcon
  },

  data () {
    return {
      counter: 0,
      isActive: false
    }
  },

  methods: {
    incrementQuantity () {
      this.counter++
      this.isActiveBtn()
    },

    decrementQuantity () {
      this.counter--
      this.isActiveBtn()
      if (this.counter < 0) {
        this.counter = 0
      }
    },

    isActiveBtn () {
      if (this.counter >= 1) {
        this.isActive = true
      } else {
        this.isActive = false
      }
    },

    addToCart () {
      this.$emit('onAddToCart', this.counter)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../offer-details';
</style>
