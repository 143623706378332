<template>
  <BaseLayout>
    <OfferDetails />
  </BaseLayout>
</template>

<script>
import BaseLayout from '../../layouts/BaseLayout'
import OfferDetails from '@/components/offer-details/Index'
export default {
  name: 'OfferDetailsPage',
  components: { OfferDetails, BaseLayout }
}
</script>

<style scoped></style>
