<template>
  <div class="menu-images p-r-24">
    <!-- <CoolLightBox
      dir="rtl"
      :items="menuImages.menu_images"
      :index="index"
      @close="index = null"
    /> -->

    <Carousel
      v-if="menuImages.menu_images.length"
      :settings="settings"
    >
      <Slide
        v-for="(image, i) in menuImages.menu_images"
        :key="`slider-image-${i}`"
        class="img-container p-l-10 p-y-10 cursor-pointer"
        @click="index = i"
      >
        <img
          :src="image"
          alt=""
        >
      </Slide>
    </Carousel>

    <div
      v-else
      class="empty-menu text-center"
    >
      <h2 class="my-4">
        {{ $t('offers.noMenu') }}
      </h2>
    </div>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
export default {
  name: 'MenuImagesTap',
  components: { Carousel, Slide, Pagination, Navigation, CoolLightBox },
  props: {
    menuImages: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data () {
    return {
      index: null,

      settings: {
        dots: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        swipeToSlide: true,
        rows: 1,
        infinite: true,
        rtl: true,
        autoplay: true,
        responsive: [
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              autoplay: true
            }
          },

          {
            breakpoint: 1197,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 1,
              infinite: true
            }
          },

          {
            breakpoint: 767,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              autoplay: true
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              autoplay: true
            }
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss">
.menu-images {
  .img-container {
    width: 160px;
    height: 190px;
    border-radius: 10px;
    overflow: hidden;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }

  .cool-lightbox__slide {
    &:not(.cool-lightbox__slide--current) {
      opacity: 0 !important;
      visibility: hidden !important;
    }
  }

  .cool-lightbox__slide--current {
    margin: 0 !important;
  }

  .slick-arrow {
    svg {
      width: 20px;
      height: 20px;

      [dir='ltr'] & {
        transform: rotate(180deg);
      }
    }

    &::before {
      display: none;
    }

    &.slick-next {
      background-color: #fff;
      width: 35px;
      height: 35px;
      color: rgb(189, 185, 185);
      z-index: 33;
      top: 50% !important;
      top: auto;
      border-radius: 50%;
      display: grid;
      place-items: center;
      box-shadow: 0 4px 14px 0 rgba($black-color, 0.2);
      right: -20px !important;

      [dir='rtl'] & {
        right: auto !important;
        left: -20px !important;
      }
    }

    &.slick-prev {
      display: none !important;
    }
  }
}
</style>
